@import "variables";
@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "../../node_modules/typi/scss/typi";
@import "components/_cards.scss";
@import "components/_layout.scss";
@import "components/_map.scss";

html {
  @include typi-base();
}

body {
  font-family: $font-brand;
  background: $color-background-invert;
  color: $color-font-invert;

  a {
    color: $color-font-invert;
    border-bottom: 0.15em solid $color-font-highlight-invert;

    &:hover {
      color: $color-font-highlight-invert;
      text-decoration: none;
    }
  }
  
  @media (max-width: 34em) { 
    margin-left: 15px;
    margin-right: 15px;
  }
}

hr {
  border-top-color: $color-font-highlight-invert;
  border-top-width: 0.7rem;
  border-top-style: dotted;
  margin-bottom: 3rem;
}

h1 { @include typi($h1-map) }

h1.blog {
  margin-top: 2em;
  margin-bottom: 0;
}

h2 { 
  margin: vr(2) 0 vr(1);
  @include typi($h2-map)
  line-height: 1.5;
}

h3 { @include typi($h3-map) }

img {
  max-width: 100%;
}

#openimage {
  display: none;
}

body.openspace #openimage {
  display: block;
}

#metanav {
  text-align: right;
  margin-top: 1em;
  font-size: 70%;
}

#header {
  background: $color-background-invert;
  color: $color-font-invert;
  padding: 1.5em 0;
  margin-top: 1em;
  margin-bottom: 3em;

  .row {
    display: flex;
    
    @media (max-width: 48em) {
      display: block;
    }
  }
  
  #logolink {
    border-bottom: 0;
  }
}

.mainnav {
  font-family: $font-brand;
  font-size: 120%;

  a {
    border-bottom: 0;
  }
  
  ul {
    position: absolute;
    bottom: 0;
    
    @media (max-width: 48em) {
      position: relative;
    }
  }
}

#content {
  margin-top: 1em;
}

#listefahrten {
  li {
    margin-bottom: 1em;
  }
  .time {
    font-weight: bold;
  }

  .stop {
    &:before {
      content: "H: ";
    }
  }

  .type {
    font-size: 85%;
  }

  .number {
    font-size: 85%;
  }

  .dest {
    font-size: 85%;
    &:before {
      content: "in Richtung: ";
    }
  }
}

#footer {
  background: $color-background-invert;
  color: $color-font-invert;
  margin-top: 4em;
  padding: 1.5em 0;
}

.jumbo {
  font-family: $font-brand;
  font-size: 200%;
  margin-bottom: 3rem;

  a {
    border-bottom: 0.15em solid $color-font-highlight-invert;

    &:hover {
      text-decoration: none;
    }
  }
}

.important {
  color: $color-font-highlight-invert;

  a {
    color: $color-font-highlight-invert;
  }
}

.map {
  margin-top: 7em;
}

blockquote {
  margin: 0 1rem;
  border-left: 2px solid #ccc;
  padding: 0 0.5rem;
}