@import "https://cdn.jsdelivr.net/font-hack/2.020/css/hack.min.css";

$color-font:                  #000000;
$color-font-invert:           #ffffff;
$color-background:            #ffffff;
$color-background-invert:     #000000;
$color-font-highlight-invert: limegreen;
$brand-primary:               $color-font-highlight-invert;

$font-brand:                  "Hack", sans-serif;

$typi: (
  null: (16px, 1.3), // Sets line-height to 1.3
  small: 18px,
  large: (20px, 1.4) // Sets line-height to 1.4
);

$h1-map: (
  null: (3.129em, 1.2),
  large: (2.3353em, 1.3)
);

$h2-map: (
  null: 2.3353em,
  large: 1.769em
);

$h3-map: (
  null: 1.769em,
  large: 1.333em
);
  
$breakpoints: (
  small: 34em,
  large: 62em
);
